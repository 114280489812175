<template>
    <div class="Revenue" :class="{ 'is-loading': isFetchingData }">
        <Toolbar>
            <div>
                <Dropdown
                    hasSearch
                    :items="drivers.filter(d => d.isVisible)"
                    :selected="selectedEmployee"
                    placeholder="Fahrer"
                    @onItemSelect="handleEmployeeSelect"
                />
                <Dropdown
                    hasSearch
                    :items="cars"
                    :selected="selectedCar"
                    placeholder="Kennzeichen"
                    @onItemSelect="handleCarSelect"
                />
                <Datepicker monthPicker @onChange="handleDateChange" :startDate="selectedFrom" />
                <Button size="small" @onClick="handleQuery" :isLoading="isFetchingData">
                    Anzeigen
                </Button>
                <div class="ToolbarActions--Group">
                    <Button
                        v-if="selectedCar || selectedEmployee"
                        v-tooltip="'In Schiten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('shifts')"
                    >
                        <i class="ri-repeat-line"></i>
                    </Button>
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Einzelfahrten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('trips')"
                    >
                        <i class="ri-steering-2-line"></i>
                    </Button>
                    <Button
                        v-if="selectedEmployee"
                        v-tooltip="'In Arbeitszeiten Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('working-time')"
                    >
                        <i class="ri-time-line"></i>
                    </Button>
                    <Button
                        v-if="selectedCar"
                        v-tooltip="'In Betriebsnachweise Anzeigen'"
                        variant="icon"
                        @onClick="handleShowOnPage('operationride')"
                    >
                        <i class="ri-car-line"></i>
                    </Button>
                </div>
            </div>
            <div>
                <Download title="Download PDF" type="pdf" @onDownload="handleDownload" />
            </div>
        </Toolbar>

        <ChartV2
            v-if="chartData.length"
            halfHeight
            type="bar"
            title=" "
            :values="chartData"
            :activeBar="activeBar"
            @onLeave="activeRow = null"
        >
            <RevenueSummary
                :currentRevenue="shiftsIncome"
                :comparisonRevenue="lastMonthIncome"
                :currentStartDate="currentSelectedFrom"
                :currentEndDate="currentSelectedTo"
                :comparisonStartDate="formatDateToPreviousMonthStart"
                :comparisonEndDate="formatDateToPreviousMonthEnd"
            />
        </ChartV2>

        <div class="Revenue-Tables" v-if="revenueByEmployee">
            <revenue-table
                :shifts="revenueByEmployee"
                title="Umsatz nach Fahrer"
                @onRowClick="props => handleRowClick({ ...props, type: 'employee' })"
            />
            <revenue-table
                :shifts="revenueByCar"
                title="Umsatz nach Fahrzeug"
                @onRowClick="props => handleRowClick({ ...props, type: 'car' })"
            />
        </div>
        <EmptyState v-else />
    </div>
</template>

<script>
import axios from 'axios';
import {
    startOfDay,
    endOfDay,
    subDays,
    format,
    startOfMonth,
    formatISO,
    subMonths,
    addDays,
    endOfMonth,
    isSameDay,
    isValid,
    parseISO,
    isPast,
    addMonths,
    isSameMonth,
} from 'date-fns';
import { de } from 'date-fns/locale';
import { euro, priceToEuroString } from '@/lib/helper';

import ChartV2 from '@/components/widgets/ChartV2';
import Datepicker from '@/components/widgets/Datepicker';
import Download from '@/components/widgets/Download';
import Button from '@/components/widgets/Button';
import Dropdown from '@/components/widgets/Dropdown';
import Toolbar from '@/components/Toolbar';
// import Row from '@/components/Row';

import { EventBus } from '@/event-bus.js';
import RevenueTable from './components/RevenueTable.vue';
import EmptyState from '@/components/EmptyState.vue';
import RevenueSummary from '@/components/RevenueChartInfo.vue';

export default {
    name: 'Revenue',
    components: {
        RevenueSummary,
        ChartV2,
        Datepicker,
        Download,
        Button,
        Dropdown,
        // Headline,
        Toolbar,
        RevenueTable,
        EmptyState,
    },
    data: () => {
        return {
            priceToEuroString,
            lastMonthIncome: 0,
            previousTimePeriod: '',
            selectedCar: null,
            selectedEmployee: null,
            selectedFrom: startOfMonth(new Date()),
            currentSelectedFrom: startOfMonth(new Date()),
            currentSelectedTo: endOfDay(new Date()),
            selectedTo: endOfDay(new Date()),
            isFetchingData: false,
            shifts: [],
            revenueByCar: {},
            revenueByEmployee: {},
            chartData: [],
            shiftsIncome: 0,
            drivers: JSON.parse(localStorage.getItem('drivers')).filter(d => d.isVisible),
            cars: JSON.parse(localStorage.getItem('cars')),
            activeRow: -1,
            activeBar: -1,
        };
    },
    created() {
        EventBus.$emit('updateChartStatus', {
            isActive: true,
            index: -1,
        });
    },
    computed: {
        formatDateToPreviousMonthStart() {
            return startOfMonth(subMonths(this.selectedFrom, 1));
        },
        formatDateToPreviousMonthEnd() {
            return endOfMonth(subMonths(this.selectedFrom, 1));
        },
        formatDateToPreviousMonthDay() {
            if (isSameMonth(this.selectedFrom, new Date())) {
                return endOfDay(subMonths(this.selectedFrom, 1));
            }
            return endOfMonth(subMonths(this.selectedFrom, 1));
        },
    },
    methods: {
        format,
        handleShowOnPage(path) {
            const { selectedFrom, selectedTo, selectedCar, selectedEmployee } = this;
            const params = {
                from: format(selectedFrom, 'yyyy-MM-dd'),
                to: format(selectedTo, 'yyyy-MM-dd'),
                car: selectedCar?.value,
                driver: selectedEmployee?.value,
            };
            this.$router.push({ name: path, query: params });
        },
        generateAdditionalText() {
            const selectedCarText = this.selectedCar?.value ?? '';
            const selectedEmployeeText = this.selectedEmployee?.value ?? '';

            const additionalTextContent = selectedCarText || selectedEmployeeText;
            return additionalTextContent ? additionalTextContent : '';
        },

        formatTimePeriod() {
            return format(this.selectedFrom, 'MMMM', { locale: de });
        },

        handleRowClick({ id, type }) {
            if (type === 'employee') {
                const driverName = this.drivers.find(d => {
                    return d.id === id;
                }).id;
                this.setActiveRow(this.revenueByEmployee[driverName]);
            } else {
                this.setActiveRow(this.revenueByCar[id]);
            }
        },
        handleEmployeeSelect({ item }) {
            this.selectedEmployee = item;
        },
        handleCarSelect({ item }) {
            this.selectedCar = item;
        },
        setActiveRow(item) {
            const driver = this.drivers.find(driver => driver.id === item.id) || {};
            const car = this.cars.find(car => car.id === item.id) || {};
            // reset all
            this.revenueByEmployee = Object.keys(this.revenueByEmployee).reduce((acc, key) => {
                acc[key] = {
                    ...this.revenueByEmployee[key],
                    isActive: false,
                };
                return acc;
            }, {});

            this.revenueByCar = Object.keys(this.revenueByCar).reduce((acc, key) => {
                acc[key] = {
                    ...this.revenueByCar[key],
                    isActive: false,
                };
                return acc;
            }, {});

            if (driver.id) {
                this.revenueByEmployee[driver.id] = {
                    ...this.revenueByEmployee[driver.id],
                    isActive: true,
                };
                this.handleEmployeeSelect({ item: driver });
                this.handleQuery();
            }
            if (car.id) {
                this.revenueByCar[car.id] = {
                    ...this.revenueByCar[car.id],
                    isActive: true,
                };
                this.handleCarSelect({ item: car });
                this.handleQuery();
            }
        },

        async handleQuery() {
            this.isFetchingData = true;
            try {
                const queryData = {
                    driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                    licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                    rangeStartAt: formatISO(this.selectedFrom), // Current period start
                    rangeEndAt: formatISO(endOfDay(this.selectedTo)), // Current period end
                };

                this.currentSelectedFrom = this.selectedFrom; // First day of current month
                this.currentSelectedTo = this.selectedTo; // Last day of current month

                // Prepare previous month query data
                const prevMonthQueryData = {
                    ...queryData,
                    rangeStartAt: formatISO(this.formatDateToPreviousMonthStart),
                    rangeEndAt: formatISO(this.formatDateToPreviousMonthEnd),
                };

                // API calls
                const url_base = `${process.env.VUE_APP_API_BASE_URL}/cpanel`;
                const shiftsPromise = axios.post(`${url_base}/shifts`, queryData, {
                    withCredentials: true,
                });
                const prevShiftsPromise = axios.post(`${url_base}/shifts`, prevMonthQueryData, {
                    withCredentials: true,
                });
                const dailyIncomePromise = axios.post(
                    `${url_base}/shifts/income/daily`,
                    queryData,
                    {
                        withCredentials: true,
                    },
                );
                const incomeCurrentPromise = axios.post(`${url_base}/shifts/income`, queryData, {
                    withCredentials: true,
                });
                const incomePrevPromise = axios.post(
                    `${url_base}/shifts/income`,
                    {
                        rangeStartAt: formatISO(this.formatDateToPreviousMonthStart),
                        rangeEndAt: formatISO(this.formatDateToPreviousMonthDay),
                    },
                    { withCredentials: true },
                );

                const [
                    shiftsResponse,
                    prevShiftsResponse,
                    dailyIncomeResponse,
                    incomeCurrentResponse,
                    incomePrevResponse,
                ] = await Promise.all([
                    shiftsPromise,
                    prevShiftsPromise,
                    dailyIncomePromise,
                    incomeCurrentPromise,
                    incomePrevPromise,
                ]);

                const shifts = shiftsResponse.data;
                const prevShifts = prevShiftsResponse.data;
                const dailyIncome = dailyIncomeResponse.data;
                const currentIncome = incomeCurrentResponse.data?.income || 0;
                const previousIncome = incomePrevResponse.data?.income || 0;

                // Check for daily values
                const dailyHasValues = dailyIncome.some(({ income }) => income > 0);

                // Process chart data based on daily income
                this.chartData = dailyHasValues
                    ? [...dailyIncome].map(({ income, date }) => ({
                          formattedValue: euro(income).format(),
                          formatter: value => euro(value).format(),
                          formattedDate: format(new Date(date), 'dd MMM yyyy', { locale: de }),
                          rawDate: date,
                          value: income,
                          date: format(new Date(date), 'dd.MM', { locale: de }),
                      }))
                    : []; // Clear chart data if no daily values exist

                // Process shifts to group by driver and car
                this.revenueByEmployee = this.processShiftsData(shifts, prevShifts, 'driverNumber');
                this.revenueByCar = this.processShiftsData(shifts, prevShifts, 'licenseNumber');

                this.shiftsIncome = currentIncome;
                this.lastMonthIncome = previousIncome;

                this.$emit('onFinishLoading');
            } catch (error) {
                console.error(error);
                this.$toasted.show('Ein Fehler ist aufgetreten.', { type: 'error' });
            } finally {
                this.isFetchingData = false;
            }
        },
        processShiftsData(currentShifts, previousShifts, groupByKey) {
            // Group shifts by driver or car within the current period
            const groupedCurrent = currentShifts.reduce((acc, shift) => {
                const key = shift[groupByKey]; // driverNumber or licenseNumber
                if (!acc[key]) {
                    acc[key] = {
                        id: key,
                        name:
                            groupByKey === 'licenseNumber'
                                ? shift?.licenseNumber
                                : shift?.driver?.name,
                        income: 0,
                        shifts: [],
                        timePeriod: {
                            start: new Date(shift.startAt),
                            end: new Date(shift.endAt),
                        },
                    };
                }
                acc[key].income += shift.finalTotalAmount;
                acc[key].shifts.push(shift);
                acc[key].timePeriod.start = new Date(
                    Math.min(acc[key].timePeriod.start, new Date(shift.startAt)),
                );
                acc[key].timePeriod.end = new Date(
                    Math.max(acc[key].timePeriod.end, new Date(shift.endAt)),
                );
                return acc;
            }, {});

            // Group shifts by driver or car within the previous period
            const groupedPrevious = previousShifts.reduce((acc, shift) => {
                const key = shift[groupByKey]; // driverNumber or licenseNumber
                if (!acc[key]) {
                    acc[key] = { income: 0 };
                }
                const currentDateEquivalent = addMonths(new Date(shift.endAt), 1);
                if (isPast(currentDateEquivalent)) {
                    acc[key].income += shift.finalTotalAmount; // Accumulate previous income
                }
                return acc;
            }, {});

            // Combine current and previous periods
            const combined = Object.keys(groupedCurrent).reduce((acc, key) => {
                const current = groupedCurrent[key];
                const previousIncome = groupedPrevious[key]?.income || 0;

                acc[key] = {
                    ...current,
                    previousIncome,
                    increase: current.income - previousIncome,
                };
                return acc;
            }, {});

            return combined;
        },
        generateChartData(shifts) {
            const dailyHasValues = shifts.some(shift => shift.finalTotalAmount > 0); // Check if any daily income exists

            if (!dailyHasValues) {
                return []; // Return an empty array if no daily values are available
            }

            // Map shifts into daily chart data
            return shifts.map(shift => {
                return {
                    date: format(new Date(shift.startAt), 'dd.MM'), // x-axis formatted date
                    value: shift.finalTotalAmount, // y-axis revenue
                    formattedValue: euro(shift.finalTotalAmount).format(),
                };
            });
        },
        setActiveChart(obj) {
            EventBus.$emit('updateChartStatus', obj);
        },
        handleDownload() {
            const queryData = {
                driverNumber: this.selectedEmployee ? this.selectedEmployee.id : null,
                licenseNumber: this.selectedCar ? this.selectedCar.id : null,
                rangeStartAt: formatISO(this.selectedFrom),
                rangeEndAt: this.selectedTo ? formatISO(endOfDay(this.selectedTo)) : null,
            };
            const urlShifts = `https://api.app.taxifusion.de/cpanel/shifts/revenue/daily/${format(
                this.selectedFrom,
                'yyyy',
            )}/${format(this.selectedFrom, 'MM')}/driver/${queryData.driverNumber ||
                '0'}/license/${queryData.licenseNumber || '0'}/pdf`;
            window.open(urlShifts, '_blank');
        },
        handleDateChange(date) {
            this.selectedFrom = date.from;
            this.selectedTo = date.to;
        },
    },
    mounted() {
        const query = this.$route.query;
        const { from, to, car, driver } = query;
        if (from && isValid(parseISO(from))) {
            this.selectedFrom = parseISO(from); // Only set if the parsed date is valid
        }
        if (to && isValid(parseISO(to))) {
            this.selectedTo = parseISO(to); // Only set if the parsed date is valid
        }
        if (car) {
            this.selectedCar = this.cars.find(c => c.value === car) || null; // Fallback to null if not found
        }
        if (driver) {
            this.selectedEmployee = this.drivers.find(e => e.value === driver) || null; // Fallback to null if not found
        }

        EventBus.$emit('updateChartStatus', {
            isActive: true,
            index: -1,
        });

        setTimeout(() => {
            this.handleQuery();
        }, 100);
    },
};
</script>

<style lang="scss">
.Revenue {
    @extend %contentWrapper;
    @extend %page;

    .Toolbar {
        margin-bottom: 25px;
    }

    .Revenue-Tables {
        display: flex;
        gap: 10px;

        > div {
            flex: 1;
        }

        @media screen and (max-width: breakpoint(tabletPortrait)) {
            flex-direction: column;
            gap: 80px;
        }
    }
}
</style>
