<template>
    <div :class="chartClass" ref="Chart">
        <div class="ChartsWrap" v-if="chartType === 'pie' && secondValues.length > 0">
            <div class="ChartContainer">
                <h2 v-if="title" v-html="title || '<br />'" />
                <apexchart
                    v-if="chartOptions && series.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="chartOptions"
                    :series="series"
                />
            </div>
            <div class="ChartContainer">
                <h2 v-if="title" v-html="secondValuesTitle || '<br />'" />
                <apexchart
                    v-if="secondChartOptions && secondSeries.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="secondChartOptions"
                    :series="secondSeries"
                />
            </div>
        </div>

        <div class="ChartsWrap" v-else-if="renderSeparately && secondValues.length > 0">
            <div class="ChartContainer">
                <h2 v-if="title" v-html="title || '<br />'" />
                <apexchart
                    v-if="chartOptions && series.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="chartOptions"
                    :series="series"
                />
            </div>
            <div class="ChartContainer">
                <h2 v-if="title" v-html="secondValuesTitle || '<br />'" />
                <apexchart
                    v-if="secondChartOptions && secondSeries.length"
                    :type="chartType"
                    :height="chartHeight"
                    :width="chartWidth"
                    :options="secondChartOptions"
                    :series="secondSeries"
                />
            </div>
        </div>

        <div v-else>
            <h2>
                {{ title }}
                <slot></slot>
            </h2>
            <apexchart
                v-if="chartOptions && series.length"
                :type="chartType"
                :height="chartHeight"
                :width="chartWidth"
                :options="chartOptions"
                :series="series"
            />
        </div>
    </div>
</template>

<script>
import VueApexCharts from 'vue-apexcharts';
import { priceToEuroString } from '@/lib/helper';
import { format } from 'date-fns';

export default {
    components: {
        apexchart: VueApexCharts,
    },
    props: {
        title: String,
        secondValuesTitle: String,
        values: {
            type: Array,
            default: () => [],
        },
        secondValues: {
            type: Array,
            default: () => [],
        },
        groupByService: {
            type: Boolean,
            default: false,
        },
        activeBar: Number,
        type: {
            type: String,
            default: 'bar', // bar by default, but can also be "pie"
        },
        halfHeight: Boolean,
        halfWidth: Boolean,
        quarterHeight: Boolean,
        threeForthHeight: Boolean,
        color: String,
        index: String,
        isSticky: Boolean,
        direction: {
            type: String,
            default: 'vertical', // vertical by default, but can also be "horizontal"
        },
        renderSeparately: {
            type: Boolean,
            default: false, // Render charts separately by default
        },
        stacked: {
            type: Boolean,
            default: false, // Enable or disable stacked bar charts
        },
    },
    data() {
        return {
            isMobile: window.innerWidth < 756,
            chartOptions: null,
            series: [],
            combinedSeries: [],
            secondSeries: [],
            secondChartOptions: null,
            chartHeight: 300,
            chartWidth: '97.5%',
        };
    },
    computed: {
        chartClass() {
            return {
                Chart: true,
                'is-half-height': this.halfHeight,
                'is-three-forth-height': this.threeForthHeight,
                'is-half-width': this.halfWidth,
                'is-quarter-height': this.quarterHeight,
                'is-inActive': this.inActive,
                'is-sticky': this.isSticky,
                'is-bar-chart': this.chartType === 'bar',
                'is-pie-chart': this.chartType === 'pie',
                'has-title': this.title?.length > 0,
                'is-single-series': !this.renderSeparately,
            };
        },
        chartType() {
            return this.type;
        },
        maxValue() {
            return Math.max(...this.values.map(v => v.value));
        },
        avgValue() {
            const total = this.values.reduce((acc, v) => acc + v.value, 0);
            return total / new Date().getDate();
        },
    },
    watch: {
        type: {
            handler() {
                this.updateChart(this.values, this.secondValues);
            },
            immediate: true,
            deep: true,
        },
        values: {
            handler(values) {
                this.updateChart(values, this.secondValues);
            },
            immediate: true,
            deep: true,
        },
        secondValues: {
            handler(values) {
                this.updateChart(this.values, values);
            },
            immediate: true,
            deep: true,
        },
    },
    methods: {
        updateChart(values, secondValues) {
            if (!values.length && !secondValues.length) return;

            if (this.chartType === 'pie') {
                this.updatePieCharts(values, secondValues);
            } else if (this.chartType === 'bar') {
                if (secondValues && secondValues.length > 0) {
                    this.updateBarChart(values, secondValues);
                } else {
                    this.updateSingleBarChart(values);
                }
            }
        },

        updatePieCharts(values, secondValues) {
            this.chartOptions = this.generatePieChartOptions(values);
            this.series = this.generatePieChartSeries(values);

            if (secondValues.length) {
                this.secondChartOptions = this.generatePieChartOptions(secondValues);
                this.secondSeries = this.generatePieChartSeries(secondValues);
            }
        },
        updateBarChart(firstValues, secondValues) {
            if (!firstValues.length && !secondValues.length) return;

            let firstCategories, secondCategories, firstData, secondData, firstColors, secondColors;

            if (this.groupByService) {
                // Group by service and sort values
                const groupedFirstValues = this.groupByServiceFunc(firstValues);
                const groupedSecondValues = this.groupByServiceFunc(secondValues);

                firstCategories = this.sortAndToArray(groupedFirstValues);
                secondCategories = this.sortAndToArray(groupedSecondValues);

                firstData = firstCategories.map(([key, value]) => value);
                secondData = secondCategories.map(([key, value]) => value);

                firstColors = firstCategories.map(([key]) => this.getColorByKey(key, firstValues));
                secondColors = secondCategories.map(([key]) =>
                    this.getColorByKey(key, secondValues),
                );
            } else {
                // Otherwise, just use the values directly
                firstCategories = firstValues.map(v => v.rawDate);
                secondCategories = secondValues.map(v => v.rawDate);

                firstData = firstValues.map(v => v.value);
                secondData = secondValues.map(v => v.value);
            }

            const totalFirstValues = firstData.reduce((acc, val) => acc + val, 0);
            const totalSecondValues = secondData.reduce((acc, val) => acc + val, 0);

            // Generate chart options
            this.chartOptions = this.generateBarChartOptions(
                firstCategories,
                totalFirstValues,
                totalSecondValues,
                firstColors,
            );

            if (this.renderSeparately) {
                this.series = this.generateBarChartSeries(firstData);
                this.secondChartOptions = this.generateBarChartOptions(
                    secondCategories,
                    totalSecondValues,
                    totalFirstValues,
                    secondColors,
                );
                this.secondSeries = this.generateBarChartSeries(secondData);
            } else {
                this.series = [
                    { name: 'Umsatz', data: firstData },
                    { name: 'Vergleich', data: secondData },
                ];
            }
        },

        updateSingleBarChart(values) {
            const categories = values.map(v => v.rawDate);
            const data = values.map(v => v.value);
            this.series = [
                {
                    name: 'Umsatz',
                    data: data,
                },
            ];

            // Define the chart options
            this.chartOptions = {
                chart: {
                    type: 'bar',
                    offsetX:5,
                    toolbar: { show: false },
                },
                plotOptions: {
                    bar: {
                        borderRadius: 3,
                        borderRadiusApplication: 'end',
                        horizontal: false,
                        columnWidth:
                            window.innerWidth < 500
                                ? '5px'
                                : window.innerWidth < 1000
                                ? '10px'
                                : '20px',
                    },
                },
                dataLabels: {
                    enabled: false,
                },
                stroke: {
                    show: false,
                    width: 1,
                    colors: ['transparent'],
                },
                xaxis: {
                    categories,
                    labels: {
                        formatter: (val, index, options) => {
                            if (options?.i % 4 === 0) return format(new Date(val), 'dd.MM');
                            return '';
                        },
                        style: {
                            colors: 'rgba(255, 255, 255, 0.75)',
                        },
                    },
                },
                yaxis: this.isMobile
                    ? {
                          show: false,
                      }
                    : {
                          labels: {
                              offsetX: 12,
                              formatter: (val, index) => {
                                  if (index % 2 === 1 && this.isMobile && val >= 1_000_00) {
                                      const formattedPrice = (val / 1_000_00).toFixed(1);
                                      return `${formattedPrice}K €`;
                                  }
                                  return index % 2 === 1 ? priceToEuroString(val) : '';
                              },
                              style: {
                                  colors: 'rgba(255, 255, 255, 0.75)',
                              },
                          },
                          title: {
                              text: '',
                              style: {
                                  color: 'rgba(255, 255, 255, 0.5)',
                              },
                          },
                      },
                fill: {
                    opacity: 1,
                    colors: ['#09d490'],
                },
                grid: {
                    borderColor: this.isMobile
                        ? 'rgba(255, 255, 255, 0)'
                        : 'rgba(255, 255, 255, 0.2)',
                },
                tooltip: {
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const value = series[seriesIndex][dataPointIndex];
                        const date = w.globals.labels[dataPointIndex];
                        return `
                        <div class="tooltip-content">
                            <span><b>${priceToEuroString(value)}</b></span>
                            <br>
                            <span>${format(new Date(date), 'dd.MM.yyyy')}</span>
                        </div>`;
                    },
                },
                annotations: {
                    yaxis: [
                        {
                            y: this.maxValue,
                            label: {
                                style: {
                                    color: '#000',
                                    background: '#09d490',
                                },
                                text: priceToEuroString(this.maxValue),
                            },
                        },
                        {
                            y: this.avgValue,
                            label: {
                                style: {
                                    color: '#000',
                                    background: '#f7d01c',
                                },
                                text: priceToEuroString(this.avgValue),
                            },
                        },
                    ],
                },
            };
        },
        sortAndToArray(groupedValues) {
            return Object.entries(groupedValues).sort(([, valueA], [, valueB]) => valueB - valueA);
        },
        generatePieChartOptions(values) {
            const groupedByService = this.groupByService
                ? values.reduce((acc, v) => {
                      if (!v.service) return acc;
                      if (!acc[v.service]) acc[v.service] = 0;
                      acc[v.service] += v.value;
                      return acc;
                  }, {})
                : null;

            const sortedEntries = Object.entries(groupedByService)
                .sort(([, valueA], [, valueB]) => valueB - valueA) // Sort descending
                .map(([key, value]) => ({ key, value }));

            const labels = sortedEntries.map(entry => entry.key);
            const series = sortedEntries.map(entry => entry.value);

            const colors = sortedEntries.map(
                entry => values.find(v => v.service === entry.key)?.color || '#09d490',
            );

            return {
                chart: {
                    type: 'donut',
                    toolbar: { show: false },
                },
                labels,
                dataLabels: {
                    enabled: true,
                    formatter: (val, opts) => `${val.toFixed(1)}%`,
                    style: {
                        fontSize: '14px',
                        color: '#000',
                        textShadow: 'rgb(0, 0, 0)',
                    },

                    dropShadow: {
                        top: 0,
                        left: 0,
                        blur: 10,
                        color: 'rgba(0,0,0,0.2)',
                        opacity: 1,
                    },
                },
                colors,
                plotOptions: {
                    pie: {
                        customScale: 1,
                        dataLabels: {
                            offset: -20,
                            minAngleToShowLabel: 25,
                        },
                    },
                },
                tooltip: {
                    custom: ({ series, seriesIndex, w }) => {
                        const value = series[seriesIndex];
                        const category = w.globals.labels[seriesIndex];
                        return `
                    <div class="tooltip-content">
                        <span><b>${category}</b></span>
                        <br>
                        <span>${priceToEuroString(value)}</span>
                    </div>`;
                    },
                },
                legend: {
                    show: true,
                    position: 'bottom',
                    labels: { colors: 'rgba(255, 255, 255, 0.7)' },
                },
            };
        },
        generatePieChartSeries(values) {
            const groupedByService = this.groupByService
                ? values.reduce((acc, v) => {
                      if (!v.service) return acc;
                      if (!acc[v.service]) acc[v.service] = 0;
                      acc[v.service] += v.value;
                      return acc;
                  }, {})
                : null;

            const sortedValues = Object.values(groupedByService).sort((a, b) => b - a); // Sorted descending amounts in the groups

            return sortedValues;
        },
        generateBarChartOptions(categories, totalFirstValues, totalSecondValues, colors) {
            return {
                chart: {
                    width: '30%',
                    type: 'bar',
                    toolbar: { show: false },
                    stacked: this.stacked, // Enable or disable stacked bars based on external prop
                },
                plotOptions: {
                    bar: {
                        dataLabels: {
                            position: 'top', // top, center, bottom
                        },
                        horizontal: this.direction === 'horizontal',

                        borderRadius: 3,
                        borderRadiusApplication: 'end',
                        distributed: !this.stacked, // Only distribute colors when not stacked
                    },
                },
                dataLabels: {
                    enabled: true,
                    formatter: (val, opts) => {
                        const percentage = (
                            (val /
                                (opts.seriesIndex === 0 ? totalFirstValues : totalSecondValues)) *
                            100
                        ).toFixed(1);
                        return `${priceToEuroString(val)}`; // Show value with percentage and label
                    },
                    offsetY: 6, // Offset the label to the top of the bar
                    offsetX: 25, // Offset the label to the top of the bar
                    dropShadow: {
                        top: 1,
                        left: 1,
                        blur: 0,
                        color: '#000',
                        opacity: 1,
                    },
                    style: {
                        fontSize: '10px',
                        colors: ['#fff'],
                    },
                },

                xaxis: {
                    categories: categories, // This now shows on the Y-axis because it's horizontal
                    labels: {
                        style: { colors: 'rgba(255, 255, 255, 0.7)' },
                        formatter: function(val, i) {
                            if (i % 2 == 0) return priceToEuroString(val); // Format the X values as currency
                        },
                    },
                },
                yaxis: {
                    labels: {
                        offsetX: 40,
                        show: !this.stacked, // Hide Y-axis labels when stacked for a cleaner view
                        style: { colors: 'rgba(255, 255, 255, 0.7)' }, // Transparent white
                        formatter: function(val) {
                            return val[0]; // Format the X values as currency
                        },
                    },
                },
                fill: { opacity: 1, colors: colors },
                grid: { borderColor: 'rgba(255, 255, 255, 0.1)' },
                tooltip: {
                    custom: ({ series, seriesIndex, dataPointIndex, w }) => {
                        const value = series[seriesIndex][dataPointIndex];
                        const categoryOrDate = w.globals.labels[dataPointIndex];
                        const percentage = (
                            (value / (seriesIndex === 0 ? totalFirstValues : totalSecondValues)) *
                            100
                        ).toFixed(1);
                        return `
                        <div class="tooltip-content">
                            <span><b>${priceToEuroString(value)}</b> (${percentage}%)</span><br>
                            <span>${categoryOrDate[0]}</span>
                        </div>`;
                    },
                },
                legend: {
                    show: this.stacked, // Only show legend when stacked
                },
            };
        },
        generateBarChartSeries(firstData, secondData) {
            const series = [{ name: 'Umsatz', data: firstData }];
            if (secondData) series.push({ name: 'Vergleich', data: secondData });
            return series;
        },
        getColorByKey(key, values) {
            const matchingEntry = values.find(v => v.service === key);
            return matchingEntry ? matchingEntry.color : '#09d490';
        },

        groupByServiceFunc(values) {
            if (!values) return {};

            return values.reduce((grouped, value) => {
                const service = value.service || 'No Service';
                if (!grouped[service]) {
                    grouped[service] = 0;
                }
                grouped[service] += value.value;
                return grouped;
            }, {});
        },
        getColorMappings(firstValues, secondValues) {
            const colorMap = {};

            const allValues = [...firstValues, ...secondValues];
            allValues.forEach(val => {
                if (val.service && val.color) {
                    colorMap[val.service] = val.color;
                }
            });

            return colorMap;
        },
        updateChartHeight() {
            const hasTitle = this.title?.length > 0;
            this.chartHeight = this.$refs.Chart.clientHeight - (hasTitle ? 70 : 10);
            if (window.innerWidth < 768) {
                this.chartHeight = Math.min(this.chartHeight, 300); // Cap height on smaller screens
            }
        },

        updateChartWidth() {
            this.isMobile = window.innerWidth < 756;
            const chartPadding = this.isMobile ? 20 : 20;
            this.chartWidth = this.$refs.Chart.clientWidth - chartPadding;
        },
    },
    mounted() {
        this.updateChart(this.values, this.secondValues);
        this.updateChartHeight();
        this.updateChartWidth();
        window.addEventListener('resize', () => {
            this.updateChartHeight();
            this.updateChartWidth();
        });
    },

    beforeDestroy() {
        window.removeEventListener('resize', this.updateChartHeight);
        window.removeEventListener('resize', this.updateChartWidth);
    },
};
</script>

<style lang="scss">
.Chart {
    width: 100%;
    background-image: linear-gradient(-42deg, color(blue-light) 0%, color(blue-dark) 100%);
    box-shadow: 5px 5px 10px 0 rgba(0, 0, 0, 0.15);
    height: 380px;
    margin-bottom: 20px;
    position: relative;
    transition: all 0.3s ease;
    overflow: hidden;
    padding: 0 0px;
    border-radius: 5px;

    &.is-sticky {
        margin-bottom: 16px;
        position: sticky;
        top: 0;
        z-index: 1;
    }

    &.is-inActive {
        filter: saturate(0) opacity(0.35);
        box-shadow: 0 7px 10px 0 rgba(0, 0, 0, 0);
    }

    &.is-half-width {
        height: 380px;
        width: 50%;
        margin-right: 30px;
        display: inline-block;
        transform: translateX(0);

        &:last-of-type {
            margin-right: 0;
        }
    }

    &.is-half-height {
        height: 240px;

        &.has-title {
            height: 290px;
        }
    }

    &.is-three-forth-height {
        height: 320px;
    }

    &.is-quarter-height {
        height: 140px;

        h2 {
            display: none;
        }
    }

    h2 {
        font-size: 1.5rem;
        color: #ffffff;
        padding-top: 15px;
        padding-left: 15px;

        @media (max-width: breakpoint(tabletLandscape)) {
            padding-top: 15px;
            padding-left: 20px;
            font-size: 1.25rem;
        }
    }

    &.is-pie-chart {
        * path {
            stroke: rgba(#000, 0.2) !important;
        }
    }

    &:not(.is-single-series) {
        * text {
            font-family: inherit !important;
            text-shadow: 1px 1px black !important;
        }
    }
}

.ChartsWrap {
    display: flex;
}

.ChartContainer {
    display: inline-block;
    // width: 50%;
    flex: 1;
    height: 320px;
    margin: 0 0px;
}

.tooltip-content {
    background: #fff;
    color: #000;
    padding: 10px;
    border-radius: 5px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
</style>
